import * as React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const NotFoundPage = () => {
  return (
    <Layout>
      <React.Fragment>
        <SEO title="Page not found | Long Rock Capital"/>
      </React.Fragment>
    </Layout>
  )
}

export default NotFoundPage